export const properties = {
    common: {
        search: "Search",
        reset: "Reset",
        create: "Create",
        edit: "Edit",
        delete: "Delete",
        back: "Back",
        confirm: "Confirm",
        close: "Close",
        confirmation: "Confirmation",
        topUp: "Top Up",
        addCredit: "Add Credit",
        error: "Error",
        success: "Success",
        list: "View Request",
    },
    promoCodePage: {
        promoCodeListing: "PromoCode Listing",
        promoCodeCreate: "Create PromoCode",
        promoCodeEdit: "Edit PromoCode",
        createSuccessful: "PromoCode Create Successfully.",
        editSuccessful: "PromoCode Edit Successfully.",
        deleteSuccessful: "PromoCode Delete Successfully.",
        totalUses: "The maximum number of times a promo code can be used",
        repeatUses: "If toggled Yes, each user can use the same code multiple times",
        maxUsesPerUser: "The maximum number of time one user can use the same promo code",
        sponsoredLogo: "Logo uploaded should have an aspect ratio of 1:1 (square shape) for optimal display",
        label: {
            promoCode: "PromoCode",
            status: "Status",
            totalUses: "Total Uses",
            repeatUses: "Repeat Uses",
            maxUsesPerUser: "Max Uses Per User",
            discountType: "Discount Type",
            discountAmount: "Discount Amount",
            startDateTime: "Start Date Time",
            endDateTime: "End Date Time",
            description: "Description",
            sponsorName: "Sponsor Name",
            sponsoredMsg: "Sponsored Message",
            sponsoredLogo: "Sponsored Logo",
        }
    },
    creditsModelsPage: {
        creditsModelListing: "VaazMe Credits Listing",
        addCreditSuccessful: "Add Credit Successfully",
        removeCredits: "Remove Credits",
        removeCreditsSuccess: "Credits removed successfully",
    },
    offlinePaymentModulePage: {
        offlinePaymentModule: "Offline Payment Module",
        editReviewStatus: "Edit Review Status",
        submitReviewSuccessful: "Status edited successfully",
    },
    userPage: {
        userListing: "User Listing",
        userEdit: "Edit User",
        updatedUser: 'Are you sure you want to edit user info for',
        editSuccessful: 'User Edit Successfully',
        successCSV: 'The CSV file has been sent to your email',
    },
    parametersPage: {
        parametersListing: "Parameters Listing",
        parameterEdit: "Edit Parameter",
        updatedParam: 'Are you sure you want to edit parameter info for',
        editSuccessful: 'Parameter Edit Successfully',

    },
    validationMessage: {
        fieldsRequired: 'This field is required.',
        promoCodeLength: 'PromoCode length should be in between 4 to 10',
        validNumber: 'Please enter a valid number.',
        maxSixDigit: 'Number must be between 1 and 999,999',
        maxPercentageDigit: 'Number must be between 1 and 100',
        maxDiscountAmtDigit: 'Number must be between 1 and 999.',
        notAllowedDecimalPoint: 'Decimal points are not allowed',
        dateTimeFill: 'DateTime From must be fill in',
        dateTimeGreater: 'DateTime From cannot be greater than',
        dateTimeGreaterCurrentDateTime: 'DateTime From must be greater than current datetime',
        maxTopUpDigit: 'Number must be between 10 and 1000',
        invalidArgument: 'Validation failed for argument',
        promoCodeInvalid: 'Only alphanumeric characters are allowed',
        sponsoredLogoExceed2MB: 'Maximum file size for Sponsored Logo is 2MB only.',
        sponsoredNameEmpty: 'Sponsor Name is required before filling up this field.',
        sponsoredMessageEmpty: 'Sponsored Message is required before filling up this field.',
        minRemoveCredit: "Number must be more than 0",
    },
    commonMessage: {
        somethingWentWrong: 'Something went wrong',
        confirmDelete: 'Are you sure want to delete this record?',
        noRecordFound: 'No Record Found.',
        confirmationCSV: 'Are you sure you want to download the CSV file?',
        confirmTopUp: 'Are you sure want add more credit for this email?',
        tokenExpired: 'Your login session already expired, please login again.'
    }
}