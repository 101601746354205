const URLS = {
  CONTENT_TYPE: {
    FORM: 'application/x-www-form-urlencoded',
    JSON: 'application/json'
  },

  PORT: {
    DEFAULT: 8080,
    ACCOUNT: 8080,
    LOGIN: 8080,
    VERIFY_USERNAME: 8080,
    CRYPTOGRAPHY: 8080,
    GRANT_ACCESS_TOKEN: 8080
  },

  METHOD: {
    POST: 'POST',

    PATCH: 'PATCH',
    PUT: 'PUT',

    DELETE: 'DELETE',

    GET: 'GET'
  },

  API: {
    LOGIN: {
      GENERATE_OTP: '/oauth/token/otp',
      VALIDATE_OTP: '/oauth/token',
      REFRESH_TOKEN: '/oauth/token/refresh/{refreshId}'
    },
    PROFILE: {
      USER: '/profiles/{userId}'
    },
    LOGOUT: '/oauth/token/invalidate',
    PROMOCODE: {
      LISTING: '/promo-code/list',
      PARAMETERS: '/promo-code/parameters',
      CREATE: '/promo-code',
      GET_PROMOCODE_ID: '/promo-code/{id}',
      DOWNLOAD: '/promo-code/export',
      REQUEST_LISTING: '/promo-code/{promoCode}/request',
      REQUEST_LISTING_DOWNLOAD: '/promo-code/promo-code-request/export'
    },
    CREDIT: {
      LISTING: '/credits/list',
      PARAMETERS: '/credits/parameters',
      UPDATE_CREDIT: '/credits/admin-add',
      DOWNLOAD: '/credits/export',
      OFFLINE_PAYMENT: {
        LISTING: '/credits/offline/list',
        DOWNLOAD: '/credits/export/offline/payment'
      },
      FILE_CONTENT: '/credits/file-content/{transactionId}',
      REMOVE: '/credits/remove',
      REVIEW: '/credits/review/submit',
    },
    USER: {
      LISTS: '/profiles',
      PARAMETERS: '/profiles/parameters',
      DETAILS: '/profiles/{userId}',
      DOWNLOAD: '/profiles/export'
    },
    PARAMETER: {
      LISTING: '/parameters',
      DETAILS: '/parameters/{parameterId}'
    }
  },
  PAGEURLS: {
    LOGIN_EMAIL: '/loginEmail',
    LOGIN_OTP: '/loginOtp',
    SIGN_OUT: '/signOut',
    DASHBOARD: '/dashboards/home',
    MANAGEMENT: {
      PROMO_CODE: {
        LISTING: '/management/promocode/listing',
        CREATE: '/management/promocode/create',
        EDIT: '/management/promocode/edit/'
      },
      CREDITS: {
        LISTING: '/management/credits/listing'
      },
      USER: {
        LISTING: '/management/user/listing',
        EDIT: '/management/user/edit/'
      },
      PARAMETER: {
        LISTING: '/management/parameter/listing',
        EDIT: '/management/parameter/edit/'
      },
      OFFLINE_PAYMENT: {
        LISTING: '/management/offlinePayment/listing',
        EDIT: '/management/offlinePayment/edit/'
      }
    }
  }
};

export default URLS;
