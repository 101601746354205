interface ErrorInterface {
  errorMsg: string;
  errorCode: string;
}


class ExceptionGeneral extends Error {
  type: string = 'label.exception.general.exception';

  msg: string = '400';

  errorCode: string = '99';

  className: string = 'ExceptionGeneral';



  constructor(errorCode: string , errorMsg: string, ) {
    super();
    if (errorMsg != null) {
      this.msg = errorMsg;
    }
    if (errorCode != null) {
      this.errorCode = errorCode;
    }
  }
}

export default ExceptionGeneral;
