class ExceptionHttp401 extends Error {
  type: string = 'HTTP 401';

  msg: string = 'label.exception.http.401';

  errorCode: string = '401';

  className: string = 'ExceptionHttp401';

  constructor(errorCode: string , errorMsg: string, ) {
    super();
    this.msg = errorMsg;
    this.errorCode = errorCode || this.errorCode;
  }
}

export default ExceptionHttp401;
