import ExceptionGeneral from '../exception/exceptionGeneral';
import ExceptionHttp400 from '../exception/exceptionHttp400';
import ExceptionNetworkError from '../exception/exceptionNetworkError';

import { ObjectUtil } from '../utils';
import { AppConstant } from '../constant';

import URLS from '../urls';
import ExceptionHttp401 from '../exception/exceptionHttp401';

let handleUnauthorized: () => void;

export function setUnauthorizedHandler(callback: () => void) {
  handleUnauthorized = callback;
}

export default class ServiceHelperHttp {
  // eslint-disable-next-line class-methods-use-this
  http(
    method: string,
    port: number,
    api: string,
    header: { Authorization?: string; },
    parameter = {},
    contentType = URLS.CONTENT_TYPE.JSON,
    _hostApi = AppConstant.HTTP_SERVER_IP  + '/api/v1/vaazme' + api
    //_hostApi = `http://localhost:9001` + '/api/v1/vaazme' + api //connect to test postman
    
  ) {
    return new Promise((resolve, reject) => {
      try {
        // console.log(' - - - ServiceHelpHTTP : [START] : *** ', {
        //   method,
        //   api,
        //   header,
        //   parameter,
        //   _hostApi,
        // });

        if (AppConstant.MOCK_ENV) {
          // console.log(' - - - ServiceHelpHTTP : [DONE - IS DEBUG] ');
          setTimeout(() => {
            resolve({});
          }, 200);
        } else {
          // let _hostApi = AppConstant.HTTP_SERVER_IP + api
          let paramQuery = '';
          const paramsJSON = {};
          const keys = Object.keys(parameter);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < keys.length; i++) {
            if (paramQuery !== '') {
              paramQuery += '&';
            }

            paramQuery += `${keys[i]}=${parameter[keys[i]]}`;
            paramsJSON[keys[i]] = parameter[keys[i]];
          }

          const _header = {
            'Content-Type': contentType,
            ...header,
          };

          // console.log(' - - - ServiceHelpHTTP : [METHOD] : => ', method);

          let _httpRequest = null;
          if (method === URLS.METHOD.GET) {
            // console.log(' - - - ServiceHelpHTTP : [HEADER] : => ', _header);
            // console.log(' - - - ServiceHelpHTTP : [URL] : => ', `${_hostApi}?${paramQuery}`);

            _httpRequest = fetch(`${_hostApi}`, {
              method,
              headers: _header,
            });
          } else {
            let _tmpParam = '';
            if (_header['Content-Type'] === URLS.CONTENT_TYPE.JSON) {
              _tmpParam = ObjectUtil.toString(paramsJSON);
            } else {
              _tmpParam = paramQuery;
            }

            // console.log(' - - - ServiceHelpHTTP : [HEADER] : => ', _header);
            // console.log(' - - - ServiceHelpHTTP : [URL] : => ', _hostApi);
            // console.log(' - - - ServiceHelpHTTP : [PARAM] : => ', _tmpParam);

            _httpRequest = fetch(_hostApi, {
              method,
              headers: _header,
              body: _tmpParam,
            });
          }

          _httpRequest
            .then(async (response) => {
              // console.log(' - - - ServiceHelpHTTP : [RESPONSE] : => ', response);

              if (response.status === 200 && response.headers.has('x-auth-rule')) {
                return {
                  httpCode: 'x-auth-otp',
                  authRule: response.headers.get('x-auth-rule'),
                  responseResult: await response.json(),
                };
              }
              if (
                // response.status === 401 &&
                response.headers.has('step-up-type')
              ) {
                const responseResult = await response.json();
                // console.log(
                //   '%cresponse:step-up-type',
                //   'color:#ff857a',
                //   response.headers.get('step-up-type'),
                //   response.headers.get('instruction-id'),
                //   responseResult.instructionId
                // );
                const instructionId =
                  response.headers.get('instruction-id') !== null
                    ? response.headers.get('instruction-id')
                    : responseResult.instructionId;
                return {
                  httpCode: 'step-up-type',
                  stepUpType: response.headers.get('step-up-type'),
                  instructionId,
                  responseResult,
                };
              }

              let responseResult = '';

              try {
                responseResult = await response.json();
              } catch (error) {
                // empty
              }

              return {
                httpCode: `${response.status}`,
                responseResult,
              };
            })
            .then((result) => {
              // console.log(' - - - ServiceHelpHTTP : [RESPONSE - JSON] : => ', result);
              const { responseResult, httpCode, stepUpType, instructionId } = result;
              if (httpCode === '200') {
                resolve(responseResult);
              } else if (httpCode === '401') {
                handleUnauthorized();
              } else {
                const {
                  errorCode,
                  code,
                  extMsg,
                  // eslint-disable-next-line camelcase
                  error_description,
                  errorMsg,
                  ResponseCode,
                  ResponseMessage,
                  message,
                  validationErrors
                } = responseResult;
                const errCode = errorCode || code || ResponseCode || '';
                let errorMessage =
                  // eslint-disable-next-line camelcase
                  extMsg || error_description || errorMsg || ResponseMessage || message || '';

                if (errorMessage) {
                  errorMessage = `${errorMessage}`;
                }

                // ABC-848 - Downtime, show error msg without errorCode.
                // if (
                //   errCode === 'BPJ120' ||
                //   errCode === 'IFT117' ||
                //   errCode === 'IBG117' ||
                //   errCode === 'RPP117' ||
                //   errCode === 'BKT235' ||
                //   errCode === 'FPX117' ||
                //   errCode === 'FTT119' ||
                //   errCode === 'PRL381' ||
                //   errCode === 'IFT118' ||
                //   errCode === 'BKT235' ||
                //   errCode === 'HTK151'
                // ) {
                //   errorMessage = extMsg || error_description || errorMsg || ResponseMessage || '';
                // }

                switch (httpCode) {
                  case '401':
                    throw new ExceptionHttp401(errCode, errorMessage);
                  case '400':
                    // FRAUD - For Non Monetary
                    throw new ExceptionHttp400(errCode, errorMessage);
                
                  // case '500':
                  //   throw new ExceptionHttp500(errCode, errorMessage);
                  // case '503':
                  //   throw new ExceptionHttp503(errCode, errorMessage);
                  // case '404':
                  //   throw new ExceptionHttp404(errCode, errorMessage);
                  // case '405':
                  // case '417':
                  // case '422':
                  //   throw new ExceptionHttp405(errCode, errorMessage);
                  default:
                    throw new ExceptionGeneral(errCode, errorMessage);
                }
              }
            })
            .catch((err) => {
              // console.log(' - - - ServiceHelpHTTP : [RESPONSE - EXCEPTION] => ', JSON.parse(JSON.stringify(err)));
              // console.log(' - - - ServiceHelpHTTP : [RESPONSE - EXCEPTION] => ', err.msg);
              // console.log(' - - - ServiceHelpHTTP : [RESPONSE - EXCEPTION] => ', err.errorCode);
              const error =  JSON.parse(JSON.stringify(err));
  
              if (ObjectUtil.hasProperty(error, 'msg')) {
                reject(error);
              } else if (error.name === 'TypeError') {
                // basically when fetch is throwing error,
                // it will be TypeError with message (access by error.message).
                console.warn('Fetch TypeError =>', error);
                reject(new ExceptionNetworkError('',''));
              } else {
                reject(new ExceptionGeneral('',error.message));
              }
            });
        }
      } catch (err) {
        // console.log(' - - - ServiceHelpHTTP : [EXCEPTION] => ', err);
        reject(new ExceptionGeneral('',err.message));
      } finally {
        // console.log(' - - - ServiceHelpHTTP : [END] : *** ');
      }
    });
  }
}

export const ServiceHelperHttpStatic = new ServiceHelperHttp();
