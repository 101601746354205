import { useEffect, useState } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import router from 'src/router';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useIdleTimer } from 'react-idle-timer';
import authStore from './store/authStore';
import { localStorageTypes } from './services/constant';
import URLS from './services/urls';
import { setUnauthorizedHandler } from './services/api/apiConfig';
import ComponentErrorDialog from './components/ErrorDialog';
import { properties } from './services/properties';

function App() {
  const content = useRoutes(router);
  const navigate = useNavigate();
  const refreshToken =
    localStorage.getItem(localStorageTypes.REFRESH_TOKEN) || '';
  const timeOutInMilliSeconds = 1000 * 60 * 710; // in milliseconds .. set refresh early abit 710 minutes , default 720 minutes
  const [timeOutLoginInMillSec, setTimeOutLoginInMillSec] = useState(
    timeOutInMilliSeconds
  );
  const [errorMsg, setErrorMsg] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');

    setUnauthorizedHandler(() => {
      setErrorMsg(properties.commonMessage.tokenExpired);
      setShowError(true);
    });

    if (!accessToken && window.location.pathname !== URLS.PAGEURLS.LOGIN_OTP) {
      navigate(URLS.PAGEURLS.LOGIN_EMAIL);
    }
  }, [navigate]);

  const handleRefreshToken = async () => {
    console.log('Refresh token');
    // make API request to refresh token here
    // if the token is successfully refreshed, you can reset the timer
    // if the token refresh fails, you can logout the user
    if (refreshToken) {
      authStore
        .getState()
        .refresh_token(refreshToken)
        .then((response) => {
          console.log('response', response);

          localStorage.setItem(
            localStorageTypes.ACCESS_TOKEN,
            response.accessToken
          );
          localStorage.setItem(
            localStorageTypes.REFRESH_TOKEN,
            response.refreshToken
          );
          localStorage.setItem(localStorageTypes.USER_AUTH, true.toString());

          const time = 1000 * 60 * (response.expiresIn / 60);
          setTimeOutLoginInMillSec(time);
        })
        .catch((error) => {
          // console.log('onSubmit error', error);
          // alert(error.msg);
        });
    }
  };

  const { reset, start } = useIdleTimer({
    // 1000 * 60 * 15, // 15 minutes
    timeout: timeOutLoginInMillSec,
    onIdle: () => {
      handleRefreshToken();
      reset();
      start();
    },
    stopOnIdle: true,
    debounce: 500
  });

  const onCloseError = () => {
    setShowError(false);
    navigate('/');
    localStorage.clear();
  };

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        {showError && (
          <ComponentErrorDialog
            errorMessage={errorMsg}
            onClose={onCloseError}
            open={showError}
          />
        )}
        <div style={{ overflow: 'auto' }}>{content}</div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
