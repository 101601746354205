export const AppConstant = {
  HTTP_SERVER_IP: (() => {
    /* ########################################### */
    /* IE not function */
    /* ########################################### */
    if (!window.location.origin) {
      const origin = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''
        }`;
      return origin;
    }
    return window.location.origin;
  })(),

  MOCK_ENV: false  // configure mock or connecting db
};

export const localStorageTypes = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  USER_AUTH: 'userAuth',
  TOKEN_TYPE: 'tokenType',
  EXPIRED_IN: 'expiredIn',
  FIRST_TIME_LOGIN: 'firstTimeLogin',
};

export const sessionStorageTypes = {
  PROFILES: 'profiles',
  USER_ID: 'userId'
};

export const MASTER_CODE = {
  STATUS_PROMO_CODE: 'STATUS_PROMO_CODE',
  DISCOUNT_TYPE: 'DISCOUNT_TYPE',
  TRANSACTION_TYPE: 'TRANSACTION_TYPE',
  REVIEW_STATUS: 'REVIEW_STATUS',
};

export const MASTER_MEMBER_CODE = {
  ACTIVE: 'ACTIVE',
  PROMO_ACTIVE: 'PROMO_ACTIVE',
  PROMO_READY: 'PROMO_READY',
  PROMO_OVER: 'PROMO_OVER',
  PROMO_DELETED: 'PROMO_DELETED',
  DISCOUNT_PERCENTAGE: 'DISCOUNT_PERCENT',
  DISCOUNT_CREDIT: 'DISCOUNT_CREDIT',
  DEDUCT: 'DEDUCT',
  TOP_UP: 'TOPUP',
  ADMIN_ADD: 'ADMIN_ADD',
  REG_PENDING: 'REG_PENDING',
  RV_UNVERIFIED: 'RV_UNVERIFIED',
}

export const DESCRIPTION_MAP = {
  PERCENTAGE: 'Percentage'
}

export const DATE_TIME_FORMAT = {
  ISO8601: 'YYYY-MM-DDTHH:mm',
  DATE_TIME_FORMAT: 'DD-MM-YYYY HH:mm'
}

export const yesOrNo = [{
  key: "Y",
  value: "Yes"
}, {
  key: "N",
  value: "No"
}];
