import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages


const Home = Loader(lazy(() => import('src/content/HomePage')));
const Users = Loader(lazy(() => import('src/content/UserPage/UserListingPage')));
const EditUser = Loader(lazy(()=> import('src/content/UserPage/EditUserPage')));

// Applications


// Components

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const LoginEmail = Loader(
  lazy(() => import('src/content/LoginEmailPage'))
);

const LoginOtp = Loader(
  lazy(() => import('src/content/LoginOtpPage'))
);

const SignOut = Loader(
  lazy(() => import('src/content/SignOutPage'))
);

const PromoCodeListing = Loader(
  lazy(() => import('src/content/PromoCode/PromoCodeListingPage'))
);

const PromoCodeCreate = Loader(
  lazy(() => import('src/content/PromoCode/PromoCodeCreatePage'))
)

const PromoCodeEdit = Loader(
  lazy(() => import('src/content/PromoCode/PromoCodeEditPage'))
)

const CreditsModuleListing = Loader(
  lazy(() => import('src/content/CreditsModule/CreditsModuleListingPage'))
)

const ParameterListing = Loader(
  lazy(() => import('src/content/Parameters/ParameterListingPage'))
)

const ParameterEdit = Loader(
  lazy(() => import('src/content/Parameters/EditParameterPage'))
)
const OfflinePayment = Loader(
  lazy(() => import('src/content/OfflinePaymentModule/OfflinePaymentListingPage'))
)

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <LoginEmail />   // <Overview/>
      },
      // {
      //   path: 'overview',
      //   element: <Navigate to="/" replace />
      // },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
      {
        path: '/loginEmail',
        element: <LoginEmail/>
      },
      {
        path: '/loginOtp',
        element: <LoginOtp/>
      },
      {
        path: '/signOut',
        element: <SignOut/>
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="home" replace />
      },
      {
        path: 'home',
        element: <Home/>
      },
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: 'user/listing',
        element: <Users/>,
      },
      {
        path: 'user/edit/:id',
        element: <EditUser/>
      },
      {
        path: 'promocode/listing',
        element: <PromoCodeListing />
      },
      {
        path: 'promocode/create',
        element: <PromoCodeCreate />
      },
      {
        path: 'promocode/edit/:id',
        element: <PromoCodeEdit />
      },
      {
        path: 'credits/listing',
        element: <CreditsModuleListing />
      },
      {
        path: 'parameter/listing',
        element: <ParameterListing />
      },
      {
        path:'parameter/edit/:id',
        element:<ParameterEdit />
      },
      {
        path: 'offlinePayment/listing',
        element: <OfflinePayment />
      },
    ]
  },
];

export default routes.map(route => ({
  ...route,
  element: (route.element)
}));


