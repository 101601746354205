import { create } from 'zustand'
import ServiceHelperHttp from '../services/api/apiConfig';
import URLS from '../services/urls';
import { StringUtil } from '../services/utils';
import { AppConstant } from '../services/constant';
import {AuthStore} from '../services/interfaces'


const authStore = create<AuthStore>((set) => ({
  generateOtp: async (param) => {
    const result = await new ServiceHelperHttp().http(
      URLS.METHOD.POST,
      URLS.PORT.DEFAULT,
      URLS.API.LOGIN.GENERATE_OTP,
      {},
      param
    );
    let resultServer = result;
    if (AppConstant.MOCK_ENV) {
      resultServer = {
        email: 'user@simplr.com.my',
      };
    }
    return resultServer;
  },
  validateOtp: async (param) => {
    const result = await new ServiceHelperHttp().http(
      URLS.METHOD.POST,
      URLS.PORT.DEFAULT,
      URLS.API.LOGIN.VALIDATE_OTP,
      {},
      param
    );
    let resultServer = result;
    if (AppConstant.MOCK_ENV) {
      resultServer = {
        accessToken:
          'eyJraWQiOiJ2YWF6bWUiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJ2YWF6bWUiLCJyb2xlIjoiUk9MRV9SRUdJU1RFUkVEIiwiaXNzIjoiaHR0cHM6XC9cL2FwcC51YXQudmFhei5tZSIsImlkIjoic2hhcmlmYWgua2hhZGlqYWhAZ21haWwuY29tIiwidHlwZSI6InVzZXIiLCJleHAiOjE2ODEyMDE2MDYsImp0aSI6ImFjMTIwMDA0LTg2YjQtMWY1ZC04MTg3LTZlOTBmNWJhMDIxMiJ9.cwf036P-7Q42HusZepWvrnabWyHu0SFaKwJQqLSnV5mc3cGRa_9C4JZHdfwRmDU73rftrUbCuXrYR8cmLV0iXHD8BiRgheB-6MYfW7ZesfhfI5Oc7C0MOMAwToOosCgVk_0zQjkVG3Hsv__CHGLZaiXgbKoMIoE66VWqgapzXCTKC7fCNEUZJYMc3Ws51B2ktuAcdMGCMnz62G_Ef33mT8EK3-qQLgJFmRE53UfbcJubcZVvHA44PCvqrsuJOdzroIR7Y5zuPgPDnLFbhoAGjPQPX21ExXWOffANyFfM69KXN40K_aNV01G4uBRlhr6RajYNUfe083aMwPPvl4grc0sOm3YQpqC04LOkTDaT3YgTOmzXpv-XtG8WfuWy_r3nrSAJ3UyjFtiXyfW3NRinW2HaqQleie7DBgqRXxbacV05q_N-b_06sRaxEOpHYEOaACHHHCNrkQ11Gd3tSoIeWEAM9ceefV4FvTCT0CoHoic-modq3HGNvzniCOvkgjHtOnMntImvwMf0Uztuq4tGPpBFBNfwhsflZnIWjfleYP_eauPEgn1js-dAaZbGNihaz0bxSS1pyFW7bBu-eEMsxg_jngaIpTvDmI3eavTgFFvhJsalBRaENAXh4VQICr3N3UCx2VwzD_wwb1LaCFblW3mCMwh3AcCfX0Z2XsM2nf8',
        refreshToken: 'HwpspwXp0lbjIy2zksJQJjWvca6fuoDS.c66b81f66af843e9992ea9b01bf4c34f',
        tokenType: 'Bearer',
        expiresIn: '43200', // seconds
        userId: 'ac120006-84c6-1a75-8184-c79d003b0018',
        lastLoginDatetime: '2023-03-24T04:40:30',
      };
    }
    return resultServer;
  },
  refresh_token: async (param) => {
    const result = await new ServiceHelperHttp().http(
      URLS.METHOD.GET,
      URLS.PORT.DEFAULT,
      StringUtil.replace(URLS.API.LOGIN.REFRESH_TOKEN, '{refreshId}', param),
      {},
      {}
    );
    let resultServer = result;
    if (AppConstant.MOCK_ENV) {
      resultServer = {
        accessToken:
          '123eyJraWQiOiJ2YWF6bWUiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJ2YWF6bWUiLCJyb2xlIjoiUk9MRV9SRUdJU1RFUkVEIiwiaXNzIjoiaHR0cHM6XC9cL2FwcC51YXQudmFhei5tZSIsImlkIjoic2hhcmlmYWgua2hhZGlqYWhAZ21haWwuY29tIiwidHlwZSI6InVzZXIiLCJleHAiOjE2ODEyMDE2MDYsImp0aSI6ImFjMTIwMDA0LTg2YjQtMWY1ZC04MTg3LTZlOTBmNWJhMDIxMiJ9.cwf036P-7Q42HusZepWvrnabWyHu0SFaKwJQqLSnV5mc3cGRa_9C4JZHdfwRmDU73rftrUbCuXrYR8cmLV0iXHD8BiRgheB-6MYfW7ZesfhfI5Oc7C0MOMAwToOosCgVk_0zQjkVG3Hsv__CHGLZaiXgbKoMIoE66VWqgapzXCTKC7fCNEUZJYMc3Ws51B2ktuAcdMGCMnz62G_Ef33mT8EK3-qQLgJFmRE53UfbcJubcZVvHA44PCvqrsuJOdzroIR7Y5zuPgPDnLFbhoAGjPQPX21ExXWOffANyFfM69KXN40K_aNV01G4uBRlhr6RajYNUfe083aMwPPvl4grc0sOm3YQpqC04LOkTDaT3YgTOmzXpv-XtG8WfuWy_r3nrSAJ3UyjFtiXyfW3NRinW2HaqQleie7DBgqRXxbacV05q_N-b_06sRaxEOpHYEOaACHHHCNrkQ11Gd3tSoIeWEAM9ceefV4FvTCT0CoHoic-modq3HGNvzniCOvkgjHtOnMntImvwMf0Uztuq4tGPpBFBNfwhsflZnIWjfleYP_eauPEgn1js-dAaZbGNihaz0bxSS1pyFW7bBu-eEMsxg_jngaIpTvDmI3eavTgFFvhJsalBRaENAXh4VQICr3N3UCx2VwzD_wwb1LaCFblW3mCMwh3AcCfX0Z2XsM2nf8',
        refreshToken: 'HwpspwXp0lbjIy2zksJQJjWvca6fuoDS.c66b81f66af843e9992ea9b01bf4c34f',
        tokenType: 'Bearer',
        expiresIn: '43200',
        userId: 'ac120006-84c6-1a75-8184-c79d003b0018',
        lastLoginDatetime: '2023-03-24T04:40:30',
      };
    }
    return resultServer;
  },
  logout: async ({ authorizeType, authorizeToken }) => {
    const result = await new ServiceHelperHttp().http(
      URLS.METHOD.GET,
      URLS.PORT.DEFAULT,
      URLS.API.LOGOUT,
      {
        Authorization: `${authorizeType} ${authorizeToken}`,
      },
      {}
    );
    let resultServer = result;
    if (AppConstant.MOCK_ENV) {
      resultServer = {
        'message:': 'Token invalidated',
      };
    }
    return resultServer;
  },
}));

export default authStore;
