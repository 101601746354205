import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { properties } from "src/services/properties";

interface Props {
  open: boolean;
  onClose: () => void;
  errorMessage: string;
}

const ErrorDialog: React.FC<Props> = ({ open, onClose, errorMessage }) => {
  return (
    <Dialog sx={{zIndex:3000}} open={open}>
      <DialogTitle><b>{properties.common.error}</b></DialogTitle>
      <DialogContent>
        <DialogContentText>{errorMessage ? errorMessage : properties.commonMessage.somethingWentWrong}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{properties.common.close}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;