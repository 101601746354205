import { func } from 'prop-types';
import { properties } from './properties';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT, MASTER_MEMBER_CODE } from './constant';

const propertiesUtil = {
  file: null,
  language: ''
};

export const StringUtil = {
  isEmpty: function (value) {
    return value == null || value == 'null' || value == '0' || value == ''
      ? true
      : value == '';
  },

  parseString: function (value) {
    return String(value);
  },

  toLowerCase: function (value) {
    return value.toLowerCase();
  },

  contain: function (value, key) {
    return (
      StringUtil.toLowerCase(value).indexOf(StringUtil.toLowerCase(key)) != -1
    );
  },

  replace: function (value, key, val) {
    return value.replace(key, val);
  },

  substring: function (value, from, to) {
    return value.substring(from, to);
  }
};

export const ObjectUtil = {
  hasProperty: function (obj, key) {
    if (obj && obj.hasOwnProperty(key)) {
      return obj[key] != null;
    }
    return false;
  },

  keys: function (obj) {
    return Object.keys(obj);
  },

  remove: function (obj, key) {
    delete obj[key];
    return obj;
  },

  join: function (obj1, obj2) {
    return { ...obj1, ...obj2 };
  },

  toObject: function (obj) {
    if (!obj) {
      return {};
    }
    return JSON.parse(obj);
  },

  toString: function (json) {
    return JSON.stringify(json);
  },

  stringToObject: function (string) {
    if (string) {
      return JSON.parse(string);
    }
    return {};
  },

  isEmpty(obj) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  },

  clone(obj) {
    return { ...obj };
  }
};

export const LanguageUtil = {
  label(key) {
    this.funcName = 'label';
    return ObjectUtil.hasProperty(propertiesUtil.file, key)
      ? propertiesUtil.file[key]
      : key;
  }
};

export const PromoCodeValidateUtil = {
  validatePromoCodeInput(value) {
    const regex = /^[a-zA-Z0-9]+$/;

    if (!value) {
      return properties.validationMessage.fieldsRequired;
    }

    if (value.length < 4) {
      return properties.validationMessage.promoCodeLength;
    }

    if (!regex.test(value)) {
      return properties.validationMessage.promoCodeInvalid;
    }

    return true;
  }
}

export const NumberValidateUtil = {
  validateDiscountPercentage(value) {
    if (!value) {
      return properties.validationMessage.fieldsRequired;
    }
    if (isNaN(value)) {
      return properties.validationMessage.validNumber;
    }

    const numValue = parseFloat(value);
    if (numValue < 1 || numValue > 100) {
      return properties.validationMessage.maxPercentageDigit;
    }
    if (!Number.isInteger(numValue)) {
      return properties.validationMessage.notAllowedDecimalPoint;
    }
    return true;
  },
  validateDiscountAmount(value) {
    if (!value) {
      return properties.validationMessage.fieldsRequired;
    }
    if (isNaN(value)) {
      return properties.validationMessage.validNumber;
    }

    const numValue = parseFloat(value);
    if (numValue < 1 || numValue > 999) {
      return properties.validationMessage.maxDiscountAmtDigit;
    }
    if (!Number.isInteger(numValue)) {
      return properties.validationMessage.notAllowedDecimalPoint;
    }
    return true;
  },
  validateUsesNumber(value) {
    if (!value) {
      return properties.validationMessage.fieldsRequired;
    }
    if (isNaN(value)) {
      return properties.validationMessage.validNumber;
    }

    const numValue = parseFloat(value);
    if (numValue < 1 || numValue > 999999) {
      return properties.validationMessage.maxSixDigit;
    }
    if (!Number.isInteger(numValue)) {
      return properties.validationMessage.notAllowedDecimalPoint;
    }
    return true;
  },
  validateTopUpNumber(value) {
    if (!value) {
      return properties.validationMessage.fieldsRequired;
    }
    if (isNaN(value)) {
      return properties.validationMessage.validNumber;
    }

    const numValue = parseFloat(value);
    if (numValue < 10 || numValue > 1000) {
      return properties.validationMessage.maxTopUpDigit;
    }
    if (!Number.isInteger(numValue)) {
      return properties.validationMessage.notAllowedDecimalPoint;
    }
    return true;
  },
  validateRemoveCreditNumber(value) {
    if (!value) {
      return properties.validationMessage.fieldsRequired;
    }
    if (isNaN(value)) {
      return properties.validationMessage.validNumber;
    }

    const numValue = parseFloat(value);
    if (numValue <= 0) {
      return properties.validationMessage.minRemoveCredit;
    }
    if (!Number.isInteger(numValue)) {
      return properties.validationMessage.notAllowedDecimalPoint;
    }
    return true;
  },
};

export const DateTimeRangeValidateUtil = {
  validateStartDataTimeFilter(startFrom, endFrom) {
    if (!startFrom && !endFrom) {
      return true;
    }

    if (!startFrom && endFrom) {
      return properties.validationMessage.dateTimeFill;
    }

    if (dayjs(startFrom).isAfter(dayjs(endFrom))) {
      return `${properties.validationMessage.dateTimeGreater} ${dayjs(endFrom).format(DATE_TIME_FORMAT.DATE_TIME_FORMAT)}`;
    }

    return true;
  },
  validateEndDataTimeFilter(startFrom, endFrom) {
    if (!startFrom && !endFrom) {
      return true;
    }

    if (startFrom && !endFrom) {
      return properties.validationMessage.dateTimeFill;
    }

    return true;
  },
  validateStartDataTime(startFrom, endFrom, selectedStatus) {
    if (!startFrom) {
      return properties.validationMessage.fieldsRequired;
    }

    if (selectedStatus === MASTER_MEMBER_CODE.PROMO_READY) {
      if (dayjs(new Date()).isAfter(startFrom)) {
        return `${properties.validationMessage.dateTimeGreaterCurrentDateTime}`;
      }

      if (dayjs(startFrom).isAfter(dayjs(endFrom))) {
        return `${properties.validationMessage.dateTimeGreater} ${dayjs(endFrom).format(DATE_TIME_FORMAT.DATE_TIME_FORMAT)}`;
      }
    }

    return true;
  },
  validateEndDataTime(startFrom, endFrom) {
    if (!endFrom) {
      return properties.validationMessage.fieldsRequired;
    }

    if (dayjs(new Date()).isAfter(endFrom)) {
      return `${properties.validationMessage.dateTimeGreaterCurrentDateTime}`;
    }
    return true;
  },
}

export const SponsoredSectionValidateUtil = {
  validateSponsoredMessage(invalid) {
    if (invalid) {
      return properties.validationMessage.sponsoredNameEmpty;
    }
    return true;
  }
}
