class ExceptionHttp400 extends Error {
  type: string = 'HTTP 400';

  msg: string = 'label.exception.http.400';

  errorCode: string = '400';

  className: string = 'ExceptionHttp400';

  constructor(errorCode: string , errorMsg: string, ) {
    super();
    this.msg = errorMsg;
    this.errorCode = errorCode || this.errorCode;
  }
}

export default ExceptionHttp400;
